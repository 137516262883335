.project-body {

	&__gallery {
		
		&-wrapper {
			display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-template-rows: auto;
				gap: 1rem;
			margin-bottom: 3rem;
		}

		&-img {
			appearance: none;
			background: $bodyBackground;
			border: none;
			height: 0;
			overflow: hidden;
			padding-bottom: 80%;
			position: relative;

			img { @include object-fit(cover, center); }
		}
	}

	&__meta {

		li {
			border-bottom: 1px solid rgba($white, .2);
			margin-bottom: 1rem;
			padding-bottom: 1rem;

			&:last-child { border: none; }

			span,
			strong,
			.floorplan__image {flex: 1;}

			span {text-align: right;}

			.floorplan__image {
				appearance: none;
				border: none;
			}

			#floorplan-lightbox__main {
				width: 100%;

				img {object-fit: contain !important;}
			}
		}

		.model-type {

			&__title {
				align-self: flex-end;
				margin-right: 1rem;
				text-decoration: none;
				width: 50%;
			}

			&__image {
				height: 0;
				padding-bottom: 25%;
				position: relative;
				overflow: hidden;
				width: 50%;

				img { @include object-fit(cover, center); }
			}
		}


	}

	@include media($screen-sm) {

		&__gallery {
			margin-bottom: 0;
			width: 60%
		}

		&__meta {
			margin-top: 4rem;
			padding-left: 3rem;
			width: 40%
		}
	}
}

.project-quote.layout-more-narrow {
	background: $tealDarkest;
	text-align: left;

	.contain {
		position: relative;

		&::before {
			background: url('/icons/quote.svg') no-repeat center;
				background-size: contain;
			content: '';
			height: 3rem;
			position: absolute;
				top: 0;
				left: -3rem;
			width: 3rem;
		}
	}

	@include media($screen-sm) {
		padding-bottom: 6rem;
		padding-top: 6rem;
	}
}

.project-quote + .blueprint {
	margin-top: 0;
}