.featured-news {
    background-color: $gray;
    padding: #{$spacing * 2} 0;
    
    .flickity-button {
        left: unset;
        top: #{-$spacing * 3};
        
        &.previous {
            right: 3.5rem;
        }
        
        &.next {
            background-color: $teal;
            right: 0;
        }

        .flickity-button-icon {
            fill: $black;
        }

        &.flickity-prev-next-button {
            transform: translateY(0);
        }

        @include media($screen-md-max, 'max') {
            top: -4rem;
        }
    }
    
    .flickity-viewport {
        position: relative;
        width: 100%;
    }
    
    &__slider {
        display: flex;
        flex-wrap: wrap;
        gap: $spacing;
        justify-content: space-between;
    }
    
    &__cell {
        margin-right: $spacing;
        overflow: hidden;
        width: 100%;
        
        &-text {
            background-color: #fff;
            color: #000;
            height: 100%;
            padding: $spacing;
            
            h3 {
                color: #000;
                font-family: $font-poppins;
                font-weight: 700;
            }
            
            .layout:first-of-type {
                margin-top: $spacing;
                
                .contain {
                    padding: 0;
                }
            }
        }
    }
    
    &__heading {
        margin-bottom: $spacing;
        opacity: 0;
        padding-bottom: #{$spacing * 2};
        position: relative;
        transition: $animationDuration opacity ease-in-out;

        &.in-view {
            opacity: 1;
        }
        
        p {
            font-size: 1.75rem;
        }
        
        &-link {
            color: $teal;
            position: absolute;
            bottom: 0.25rem;
            right: 8rem;
        }
    }
    
    &__image {
        height: 0;
        overflow: hidden;
        padding-bottom: calc(100% * 2 / 3);
        position: relative;
        
        img {
            @include object-fit(cover, center);
        }
    }
    
    @include media($screen-sm-max, 'max') {
        &__cell {
            &-text {
                h3 {
                    font-size: 1.375rem;
                }
            }
        }
    }
    
    @include media($screen-sm) {
        &__cell {
            width: calc((100% / 3) - #{$spacing / 2});
        }
        
        &__heading {
            padding-bottom: 0;
            padding-right: #{$spacing * 10};
            
            &-link {
                bottom: .75rem;
            }
        }
        
        &__slider {
            flex-wrap: nowrap;
        }
    }
}