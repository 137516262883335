// Not a flex layout. An individual component of the kit single page

.kit-specs {
	@include vertical-padding();
	background: linear-gradient(-20deg, #474747 -10%, $grayDarker 50%);
	margin-bottom: 0 !important;
	margin-top: 0 !important;

	&__floorplans {
		margin-bottom: 3rem;

		overflow: 0;
		position: absolute;
		visibility: hidden;

		&.active {
			opacity: 1;
			position: relative;
			visibility: visible;
		}
	}

	&__sizes {
		margin-bottom: 3rem;
	}

	&__size {
		overflow: 0;
		position: absolute;
		visibility: hidden;

		&.active {
			opacity: 1;
			position: relative;
			visibility: visible;
		}
	}

	&__section {
		background-size: 2.5rem 2.25rem;
		background-position: left top;
		background-repeat: no-repeat;
		border-bottom: 1px solid rgba($white, .2);
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		padding-left: 4rem;

		&.sq-ft { background-image: url('/icons/sq-ft.svg'); }
		&.energy { background-image: url('/icons/energy.svg'); }
		&.floors { background-image: url('/icons/floors.svg'); }
		&.cost { background-image: url('/icons/cost.svg'); }
		&.bedrooms { background-image: url('/icons/bedrooms.svg'); }
		&.measurements { 
			background-image: url('/icons/measurements.svg');
			border: none;
			margin-bottom: 0;
			padding-bottom: 0;

			li {
				display: flex;
				justify-content: space-between;
			}
		}

		.h6 {
			font-size: 1.125rem;
			margin-bottom: 1rem;
		}

		ul {
			list-style: none;
			margin-bottom: -.5rem;
			padding: 0;

			li {
				margin-bottom: .5rem;
			}
		}
	}

	&__floorplans {

		&-main {
			margin-bottom: 1rem;

			&-img {
				background: white;
				cursor: pointer;
				height: 0;
				overflow: hidden;
				padding-bottom: 70%;
				position: relative;
				width: 100%;

				img { @include object-fit(contain, center); }
			}
		}

		&-nav {
			padding: 0 1rem 0 2rem;

			&-img {
				background: white;
				height: 0;
				margin-left: 1rem;
				overflow: hidden;
				padding-bottom: 30%;
				position: relative;
				width: calc(33.33% - 1rem);

				img { @include object-fit(contain, center); }
			}

			.flickity-button {
				background-color: rgba($white, .2);
				border-radius: 0;
				height: 100%;
				transition: .25s background-color;
				width: 1.5rem;

				&.previous {
					left: 0;
				}

				&.next {
					right: 0;
				}

				&:hover,
				&:focus {
					background-color: rgba($white, .1);
				}

				svg path {fill: $white;}
			}
		}
	}

	@include media($screen-sm) {
		&__floorplans {
			margin-bottom: 0;
			width: 40%;
		}
	
		&__right {
			padding-left: 3rem;
			width: 60%;
		}

		&__size {
			display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(4, auto);
				grid-column-gap: 1.5rem;

			.sq-ft { grid-area: 1 / 1 / 2 / 2; }
			.energy { grid-area: 2 / 1 / 3 / 2; }
			.floors { grid-area: 3 / 1 / 4 / 2; }
			.cost { grid-area: 1 / 2 / 3 / 3; }
			.bedrooms { grid-area: 3 / 2 / 4 / 3; }
			.measurements {
				grid-area: 4 / 1 / 5 / 3;

				ul {
					columns: 2;
						column-gap: 3rem;
				}
			}
		}
	}

	@include media($screen-md) {

		&__right {
			padding-left: 4.5rem;
		}
	}
}
