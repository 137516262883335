/**
 * Collapsible Layout
 * DBS>Interactive
 */

 .collapsible {
	@include default-margin;

	.collapsibles-wrapper {
		border: 1px solid $gray;
		border-radius: 12px;
		overflow: hidden;
	}

	.collapsible-row {
		overflow: hidden;
		margin-bottom: .5rem;

		&:first-of-type {
			border: none;
		}

		&:last-of-type {
			.collapsible-row-content .rich-text {border-bottom: none;}

			.collapsible-row-header {border-bottom: none;}
		}

		// Row Header
		&__header {
			background: $charcoal;
			border: none;
			color: $textColor;
			cursor: pointer;
			font-weight: normal;
			margin-bottom: 0;
			margin-top: 0 !important;
			padding: 1.5rem 4rem 1.5rem 1.5rem;
			position: relative;
			text-align: left;
			transition: background .25s;
			width: 100%;

			&:hover,
			&:focus,
			&:active {
				background: $tealMid;
				box-shadow: none !important;
			}

			&:after {
				background: url('/icons/collapsible-open.svg');
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
				content: '';
				display: block;
				height: 2rem;
				position: absolute;
					top: 50%;
					right: 2rem;
				transition: transform .25s;
				transform: translate(0, -50%) scale(.8);
				width: 2rem;
			}

			&.active {
				background: $tealMid;

				&:after {
					transform: translate(0, -50%) scale(.8) rotate(180deg);
				}
			}
		}

		// Row Content
		&__content--wrapper {
			background: $tealDarker;
			height: 0;
			overflow: hidden;
			transition: height .3s ease-in-out 0s;
			
			.collapsible-row__content {
				border-top: .5rem solid $bodyBackground;
				padding: 2rem;
			}
		}
	}
	
	.collapsible-section {
		@include default-margin;
		
		&:first-child {
			margin-top: 0;
		}
	}
}
