.form-layout {

	.freeform-form-container {
		background: $charcoal;
		border-radius: 1.25rem;
		padding: 1rem;
		
		@include media($screen-sm) {
			padding: $spacing;
		}

		.freeform-row {
			.floating {
				.freeform-input {
					background: transparent;
					border: none;
					border-bottom: 1px solid $white;
					border-radius: 0;
					color: $white !important;
		
					&::placeholder {
						color: $white;
					}

					&:-webkit-autofill,
					&:-webkit-autofill:hover, 
					&:-webkit-autofill:focus, 
					&:-webkit-autofill:active{
						-webkit-box-shadow: 0 0 0 30px $charcoal inset !important;
						-webkit-text-fill-color: $white !important;
					}

					+ .freeform-label {
						background: transparent !important;
						color: $white !important;
						font-family: $font-poppins;
						font-weight: 600;
						opacity: 1 !important;
						transform: .25s !important;

						&::after {
							color: $white !important;
						}
					}
				}

				textarea.freeform-input {
					background: $bodyBackground;
					border: 1px solid #595959;
					border-radius: .5rem;
				}
			}
		}

		.freeform-fieldtype-select.floating,
		.freeform-fieldtype-dropdown.floating {

			.freeform-label {
				background: transparent;
				color: $white !important;
				font-family: $font-poppins;
				font-size: 1rem;
				font-weight: 600;
				opacity: 1 !important;
				padding-left: 1rem;
				position: static;
			}

			.freeform-input {
				background: url('/icons/arrow-down-white.svg') no-repeat calc(100% - 1rem) center;
					background-color: $bodyBackground;
					background-size: 1.5rem;
				border: 1px solid #595959;
				appearance: none;
				border-radius: .5rem;
				font-family: $font-poppins;
				padding: 1rem;
			}
		}
	}

	// Standard Checkboxes
	.freeform-fieldtype-checkbox,
	.freeform-fieldtype-checkbox_group,
	.freeform-fieldtype-checkboxes,
	.freeform-fieldtype-mailing_list {
		display: flex;

		.freeform-label,
		label {
			color: $white;
			font-family: $font-poppins;
			font-weight: 700 !important;
		}

		.freeform-input {
			appearance: none;
			background: $bodyBackground;
			border: 1px solid #595959;
			border-radius: .5rem;
			height: 1.5rem !important;
			padding: 0 !important;
			width: 1.5rem !important;
			min-width: 1.5rem;
			
			&:checked {
				background-image: url('/icons/check.svg');
				background-size: 90%;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}

	.basic-radio {
		color: $white;
		font-family: $font-poppins;
		font-weight: 700 !important;
		margin-bottom: .5rem !important;

		& ~ label,
		& ~ .input-group-one-line label {
			color: $white;
			display: flex !important;
				align-items: center;
			font-family: $font-poppins;
			margin-bottom: .5rem !important;

			.freeform-input {
				appearance: none;
				background: $bodyBackground;
				border: 1px solid #595959;
				border-radius: .5rem;
				height: 1.5rem !important;
				padding: 0 !important;
				width: 1.5rem !important;
				min-width: 1.5rem;
				
				&:checked {
					background-image: url('/icons/check.svg');
					background-size: 90%;
					background-position: center;
					background-repeat: no-repeat;
				}
			}
		}

		& ~ .input-group-one-line {
			label:first-child {padding-right: 1.5rem;}
		}
	}

	.freeform-fieldtype-checkbox_group,
	.freeform-fieldtype-checkboxes {
		display: block;

		label {
			display: flex !important;
			margin-bottom: .75rem !important;
		}

		.freeform-input{
			&:checked {
				background-image: url('/icons/check.svg');
				background-size: 90%;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}

	// Contact Specific Checkboxes
	.contact-form {
		.freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group,
		.freeform-row:nth-child(13) .freeform-fieldtype-radio_group,
		.freeform-row:nth-child(13) .freeform-fieldtype-radios,
		.freeform-row:nth-child(13) .freeform-fieldtype-checkboxes,
		.freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group,
		.freeform-row:nth-child(14) .freeform-fieldtype-radio_group,
		.freeform-row:nth-child(14) .freeform-fieldtype-radio_group {
			display: flex;
				flex-wrap: wrap;
			font-family: $font-poppins;
			margin-left: -1rem !important;
			margin-right: -1rem !important;
			width: calc(100% + 2rem);
	
			.freeform-label:first-child {
				color: $white;
				display: block !important;
				font-weight: bold;
				margin-bottom: 2rem !important;
				margin-top: 3rem;
				padding-bottom: 1rem;
				position: relative;
				text-align: center;
				width: 100%;
	
				&:before {
					border-bottom: 1px solid $teal;
					content: '';
					position: absolute;
						bottom: 0;
						left: 50%;
					transform: translateX(-50%);
					width: 3rem;
				}
	
				&::after {
					color: $white !important;
				}
			}
	
			label:not(:first-child) {
				box-shadow: 0px .25rem 1rem 0px rgba(0, 0, 0, 0.25);
				padding: .75rem .75rem 6rem;
				padding-bottom: 6rem;
				color: $white;
				font-size: .9rem;
				font-family: $font-noto-serif;
				font-weight: 700;
				margin: 0 .5rem 1.5rem;
				position: relative;
				width: calc(50% - 1rem);

				@include media($screen-sm) {
					font-size: 1rem;
					margin: 0 1rem 1.5rem;
					width: calc(50% - 2rem);
				}
			}
	
			.model-checkbox {
				appearance: none !important;
				border: none !important;
				border-radius: 4px !important;
				cursor: pointer;
				height: 100% !important;
				position: absolute;
					bottom: 0;
					left: 0;
				transform: none !important;
				width: 100% !important;

				&:checked {
					border: 1px solid $teal !important;

					&::before {
						@include size(1.5rem);
						background-image: url('/icons/check.svg');
						background-size: 90%;
						background-position: center;
						background-repeat: no-repeat;
						content: '';
						position: absolute;
							top: .5rem;
							right: .75rem;
					}
				}

				&:focus {
					box-shadow: none !important;
				}
			}
	
			@include media($screen-sm) {
	
				label:not(:first-child) {
					width: calc(25% - 2rem);
				}
			}
		}
	
		#form-input-model_checkboxes-0 {background: url('/images/kits/modern_wo_trees_800.png') no-repeat right bottom;}
		#form-input-model_checkboxes-1 {background: url('/images/kits/cottage_wo_trees_800.png') no-repeat right bottom;}
		#form-input-model_checkboxes-2 {background: url('/images/kits/contemporary_wo_trees_800.png') no-repeat right bottom;}
		#form-input-model_checkboxes-3 {background: url('/images/kits/carriage_wo_trees_800.png') no-repeat right bottom;}
		#form-input-model_checkboxes-4 {background: url('/images/kits/ranch_wo_trees_800.png') no-repeat right bottom;}
		#form-input-model_checkboxes-5 {background: url('/images/kits/duplex_wo_trees_800.png') no-repeat right bottom;}
		#form-input-model_checkboxes-6 {background: url('/images/kits/a-frame_wo_trees_800.png') no-repeat right bottom;}
	
		#form-input-model_checkboxes-0,
		#form-input-model_checkboxes-1,
		#form-input-model_checkboxes-2,
		#form-input-model_checkboxes-3,
		#form-input-model_checkboxes-4,
		#form-input-model_checkboxes-5,
		#form-input-model_checkboxes-6 {
			background-size: auto 44%;

			@include media($screen-sm) {
				background-size: auto 70%;
			}
		}
	}

	.freeform-form button.button {
		background-color: $white !important;
		color: $tealDark !important;

		&:hover,
		&:focus,
		&:active {
			background-color: $tealDark !important;
			color: $white !important;
		}
	}
}