.builds {

	&__header {
		margin-bottom: 4.5rem;

		&-text {
			animation: opacity $animationDuration both;
			margin-bottom: $spacing*2;
			max-width: 38rem;

			p {
				max-width: 29rem;
			}
		}

		&-map {
			margin-left: -1rem;
			margin-right: -1rem;
			position: relative;

			&-dots {
				height: 100%;
				position: absolute;
					top: 0;
					left: 0;
				width: 100%;
			}

			&--image {
				@include size(85%);
				border-radius: 50%;
				opacity: 0;
				overflow: hidden;
				position: absolute;
					top: 50%;
					left: 50%;
				transform: translate(-50%, -50%);
				transition: .25s;

				img { @include object-fit(cover, center); }
			}

			// Position values are set in twig template from CMS
			&-dot {
				@include size(.25rem);
				animation: partialOpacity .25s both;
				background: rgba(0, 0, 0, .5);;
				border-radius: 50%;
				position: absolute;
				transform-origin: bottom;
				
				&::before {
					@include size(.35rem);
					background: $white;
					border-radius: 50%;
					content: '';
					position: absolute;
						left: 50%;
						top: 50%;
					transform: translate(-50%, -50%);
					z-index: 2;
				}

				&.featured {
					@include size(.5rem);
					animation: opacity .25s both;
					background: white;
					border-radius: 50%;
					transition: transform .25s;

					&::before {
						@include size(1rem);
						opacity: .17;
						pointer-events: none;
						transition: .25s opacity;
					}

					&:hover,
					&:focus {
						transform: scale3d(5, 5, 1);
						z-index: 20;

						.builds__header-map--image {
							opacity: 1;
						}

						&::before {
							opacity: 0;
						}
					}
				}

				&.no-link {

				}

				@for $i from 1 to 100 {
					&:nth-child(#{$i}) { animation-delay: $i * 0.06s; }
				}
			}
		}
	}

	&__filter {
		background: #2f2f2f;
		border-radius: 0 14px 14px 14px;
		margin-bottom: 3rem;
		margin-top: 6rem;
		padding: $spacing;
		position: relative;

		// Component label
		&-label {
			background: #2f2f2f;
			border-radius: 18px 18px 0 0;
			padding: 1rem;
			position: absolute;
				bottom: 100%;
				left: 0;

			h2 {
				font-size: 1rem;
				font-weight: 400;
				letter-spacing: 2.5px;
				line-height: 1;
			}
		}

		// Form labels
		label {
			display: block;
			margin-bottom: 0;
		}

		select {
			appearance: none;
			background-color: transparent;
			border: none;
			color: $textColor;
			cursor: pointer;
			margin-left: -.75rem;
			padding: .75rem;
		}

		&-section {

			&:nth-child(3) {
				border-bottom: 1px solid rgba($white, .2);
				border-top: 1px solid rgba($white, .2);
				// margin-bottom: 1rem;
				margin-top: 1rem;
				padding-bottom: 1rem;
				padding-top: 1rem;
			}
			
			&:nth-child(4) {
				border-bottom: 1px solid rgba($white, .2);
				margin-bottom: 1rem;
				// margin-top: 1rem;
				padding-bottom: 3rem;
				padding-top: 1rem;
			}
		}

		&-search {

			&-wrap {
				position: relative;
			}

			&-input {
				appearance: none;
				background: transparent;
				border: none !important;
				color: $white;

				&::placeholder { color: $white; }
			}

			&-submit {
				@include size(2rem);
				background: url('/icons/filter-search.svg') no-repeat center;
					background-size: 2rem;
				position: absolute;
					top: 50%;
					right: .75rem;
				transform: translateY(-50%);
			}
		}

		@include media($screen-sm) {
			max-width: 60rem;
			margin-bottom: 0;

			&-section {
				flex: 1;

				&:nth-child(2) {
					padding-right: $spacing;
				}

				&:nth-child(3) {
					border-bottom: none;
					border-left: 1px solid rgba($white, .2);
					border-right: 1px solid rgba($white, .2);
					border-top: none;
					margin-bottom: 0;
					margin-top: 0;
					padding-bottom: 0;
					padding-left: $spacing;
					padding-right: $spacing;
					padding-top: 0;
				}

				&:nth-child(4) {
					border-bottom: none;
					border-top: none;
					border-right: 1px solid rgba($white, .2);
					margin-bottom: 0;
					margin-top: 0;
					padding-bottom: 0;
					padding-left: $spacing;
					padding-right: 2.25rem;
					padding-top: 0;
				}

				&:nth-child(5) {
					padding-left: $spacing;
				}

				.builds__filter-search-wrap {
					margin-left: -.6rem;
				}
			}
		}
	}

	&__grid {
		display: grid;
			gap: #{$spacing*2} $spacing;
			grid-template-columns: repeat(1, 1fr);
	}

	&__item {
		opacity: 0;
		transition: $animationDuration opacity ease-in-out;

		&.in-view {
			opacity: 1;
		}

		&-main {
			height: 0;
			overflow: hidden;
			padding-bottom: 60%;
			position: relative;
			width: 80%;
			
			img { @include object-fit(cover, center); }

			&-label {
				background: rgba($black, .8);
				padding: .5rem .75rem;
				position: absolute;
					bottom: 0;
					left: 0;
			}
		}

		&-thumbnails {
			display: flex;
			margin-left: 1rem;
			margin-right: 0;
			width: calc(20% - 1rem);
		}

		&-thumbnail {
			height: 0;
			overflow: hidden;
			padding-bottom: 75%;
			position: relative;
			width: 100%;

			img { @include object-fit(cover, center); }

			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		}

		&--subheading {
			max-height: 0;
			overflow: hidden;
			transition: .25s 0s;

			p {
				max-width: 0;
				transition: .25s .25s;
			}
		}

		&:hover {
			.builds__item--subheading {
				max-height: 4rem;
				transition: .25s .25s;

				p {
					max-width: 25rem;
					transition: .25s 0s;
				}
			}
		}
	}

	&__range {
		margin-top: 1rem;

		.noUi-base {

		}

		.noUi-connect {
			background: $teal;
		}

		.noUi-handle {
			border-radius: 50%;
			box-shadow: none;
			height: 23px !important;
			width: 23px !important;

			&::before,
			&::after {content: none;}

			.noUi-tooltip {
				bottom: -145%;
				font-size: .8rem;
				padding: 1px 3px;
			}
		}

		&.noUi-horizontal {
			height: 12px;
		}
	}

	@include media($screen-sm) {

		&__header {
			margin-bottom: $spacing*3;
			position: relative;

			&-text {
				padding-right: 3rem;
				width: 50%;

				h1 {font-size: 3rem;}
			}

			&-map {
				margin: 0;
				position: absolute;
					top: 0;
					right: -1rem;
				width: 55%;
			}
		}	

		&__grid {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@include media($mobileNavBP) {
		margin-top: 0;
		padding-top: 12rem;
	}

	@include media(82rem) {
		
		&__header {

			&-text {
				h1 {font-size: 3.6rem;}
			}

			&-map {
				top: -1.5rem;
				right: -6%;
				width: 64%;
			}
		}
	}

	@include media(86rem) {

		&__header {

			&-map {
				right: -7.5%;
				width: 64%;
			}
		}
	}
}