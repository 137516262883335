.kits-layout {
	$kitInterval: .2s;

	&__heading,
	.button {
		opacity: 0;
		transition: $animationDuration opacity ease-in-out, background .25s, color .25s;
	}

	.button {
		transition-delay: $kitInterval*7;
	}

	&__wrapper {
		margin-bottom: $spacing;
	}

	&__kit {
		margin-right: 2rem;
		width: calc(50% - 2rem);

		&-image {
			height: 0;
			margin-bottom: .75rem;
			overflow: hidden;
			padding-bottom: 60%;
			position: relative;

			img { @include object-fit(contain, center); }
		}
	}

	.animation-trigger.in-view ~ {
		.kits-layout__heading,
		p .button  {opacity: 1;}
	}

	.flickity-button {
		background: url('/icons/slider-arrow.svg') no-repeat center !important;
			background-size: 100%;
		opacity: .25;
		transition: .25s opacity;
		transform: none;

		&.previous {
			background-image: url('/icons/slider-arrow-back.svg') !important;
			left: -1rem;
		}

		&.next {
			right: -1rem;
		}

		&:hover,
		&:focus {
			opacity: 1;
		}

		.flickity-button-icon {display: none;}
	}

	@include media($screen-sm) {

		&__kit {
			width: calc(33.33% - 2rem);
		}
	}

	@include media($screen-md) {

		&__kit {
			width: calc(17% - 2rem);
		}

		.flickity-button {

			&.previous {left: -2rem;}
			&.next {right: -2rem;}
		}
	}
}