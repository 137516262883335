/**
 * Table Styles with Responsive Design
 * Based on a CodePen Example by James TW:
 * https://codepen.io/james-tw/pen/GoaXrz
 */

/* Variables */
$table-header-font-weight: 400;
$table-header-font-color: $white;

$table-cell-padding: 12px 15px;

$table-bg-accent: $gray;
$table-bg-hover: rgba(0,0,0,.05);
$table-border-color: #e0e0e0;
$table-bg-inverse: #1A3D96;


/**
 * Default styles
 */
table {
    border-collapse: collapse;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin: 0 auto 1em;
	max-width: 100%;
    min-width: 400px;
	width: 100%;

	tbody {
		display: block;
        
        tr {
            border-bottom: 1px solid $teal;
            
            &:nth-of-type(even) {
            	background-color: $grayDarker;
            }
            
            &:last-of-type {
            	border-bottom: 2px solid $teal;
            }
        }
	}
    
    thead {
        tr {
            background-color: $tealDark;
            color: $white;
            text-align: left;
        }
    }

	tr {
		border: 1px solid $table-border-color;
		display: block;
		margin-bottom: $table-cell-padding;
		transition: background-color .25s ease;
	}

	th,
	td {
		border-top: 0;
		padding: $table-cell-padding;
		transition: background-color .25s ease;
		text-align: left;
		vertical-align: top;
	}

	th {
		border-bottom: 1px solid rgba(0,0,0,.12);
		color: $table-header-font-color;
		font-weight: $table-header-font-weight;
		vertical-align: bottom;
	}

	td {
		display: block;
		text-align: right;
	}

	tfoot {
		border-top: 1px solid rgba(0,0,0,.12);
		td {
			color: $table-header-font-color;
			font-weight: $table-header-font-weight;
		}
	}

	/**
	 * Responsive desktop styles
	 */
	@include media($screen-sm) {
		tbody {
			display: table-row-group;
		}

		tr {
			border: none;
			display: table-row;
		}

		td {
			display: table-cell;
			text-align: left;
		}
	}

	/**
	 * Mobile only styles
	 */
	@include media($screen-sm-max,'max') {
		thead,
		tfoot { // Hide the header and footer on mobile
			display: none;
		}

		td[data-title]:before {
			color: $table-header-font-color;
			content: attr(data-title);
			float: left;
			font-size: inherit;
			font-weight: $table-header-font-weight;
		}

		// Add 'class' specificity
		&:not(._) td:first-child {
			background-color: $grayDarker;
			border: none;
			color: $white;
			font-weight: 500;
			text-align: left;

			&::before { // Removes the data-title attr from the first column.
				display: none;
			}
		}
	}
}

.blog-post__content.bg-white {
	thead {
		h1, h2, h3, h4, h5, h6 {
			color: white;
		}
	}

	table tbody {
		// display: block;
        
        tr {
            border-bottom: 1px solid $teal;
            
            &:nth-of-type(even) {
            	background-color: $grayLightest;
            }
            
            &:last-of-type {
            	border-bottom: 2px solid $teal;
            }
        }
	}
}


/* Table classes -- Add manually to the table element within the CMS */


/**
 * Bordered table
 *
 * Add horizontal borders between columns.
 */
.table--bordered {
	border: 0;

	th,
	td {
		border-bottom: 1px solid $table-border-color;
		@include media($screen-sm) {
			border: 1px solid $table-border-color;
		}
	}

	thead th,
	thead td {
		border-bottom-width: 2px;
	}
}


/**
 * Zebra-striping
 * Default zebra-stripe styles (alternating gray and transparent backgrounds)
 */
 .table--stripes {
	td:nth-child(odd) {
		background-color: $table-bg-accent;
	}
}


/**
 * Hover effect styling
 */
@include media($screen-sm) {
	.table--hover {
		tr:hover {
			cursor: pointer;

			td,
			td:first-child {
				background-color: $table-bg-hover;
			}
		}
	}
}
