/**
 * Two Columns Flex Layout
 * DBS Interactive
 */

.two-columns {

	&__left,
	&__right {
        opacity: 0;
		position: relative;
        transition: opacity $animationDuration ease-in-out, transform $animationDuration ease-in-out;

		img {display: block;}

        &.has-img {
			opacity: 0;
			transition: opacity $animationDuration ease-in-out;
		}
	}

    &__left {
        transform: translate3d(-2rem, 0, 0);
    }

    &__right {
        transform: translate3d(2rem, 0, 0);
    }

    .animation-trigger.in-view ~ {
		.has-img {opacity: 1;}

        .two-columns__left,
        .two-columns__right {
            opacity: 1;
            transform: translate3d(0,0,0);
        }
	}

	@include media($screen-sm-max, 'max') {
		&__left {margin-bottom: $spacing;}
	}

	@include media($screen-sm) {
		&__left,
		&__right {
			width: calc(50% - #{$spacing*2});
		
			&.contain-img {img {@include object-fit(contain, center);} }
			&.cover { img {@include object-fit(cover, center);} }

			&.contain-img,
			&.cover {align-self: stretch;}
		}
	}
	
	&.two-thirds-right {
        .two-columns__left {
            @include media($screen-sm) {
                width: calc(38% - 3rem);
            }
        }

        img { // ensures that the ratio effect is not lost by a fixed-width image.
            height: auto;
            width: 100%;
        }

        .two-columns__right {

            &.bg-img {padding-left: 0;}

            @include media($screen-sm) {width: calc(62% - 3rem);}
        }
    }

    // Left side larger
    &.two-thirds-left {

        img {
            // ensures that the ratio effect is not lost by a fixed-width image.
            height: auto;
            width: 100%;
        }

        @include media($screen-sm) {
            .two-columns__left {
                width: calc(62% - 3rem);

                &.bg-img {padding-right: 0;}
            }

            .two-columns__right {
				width: calc(38% - 3rem);
			}
        }
    }
}