/**
 * Page Header Flex Layout
 * DBS>Interactive
 */

 .landscape-cta {
	display: flex;
        align-items: center;
    height: auto;
	margin-top: 0;
	position: relative;
    width: 100%;

	&__image {
		@include size(100%);
		position: absolute;
			top: 0;
			left: 0;

		img { @include object-fit(cover, center); }
	}

	&__content {
        display: grid;
			grid-template-columns: repeat(1, 1fr);
			gap: $spacing;
        width: 100%;
	}

    &__row {
        background: rgba(28, 33, 38, 0.95);
	    padding: 4em 2em;
        position: relative;
        
		@include media($screen-sm) {
            margin: 5em 0 5em 0;
			max-width: 22em;
        }
        
        h2, h3, h4, h5, h6, p, a, ul, li {color: $white;}

		p a:not(.button) {
            border-color: $white;
            justify-content: space-between;
            
			&:after {background-color: transparent;}
		}

		opacity: 0;
		transform: translate3d(0, 1.5rem,0);
		transition: $animationDuration opacity ease-in-out, $animationDuration transform ease-in-out;

		$rowInterval: .35s;
		&:nth-child(2) {transition-delay: $rowInterval;}
		&:nth-child(3) {transition-delay: $rowInterval*2;}

		&.in-view {
			opacity: 1;
			transform: translate3d(0, 0,0);
		}
    }

	@include media($screen-sm) {

		&__content {
			grid-template-columns: repeat(3, 1fr);
		}

	}
}
