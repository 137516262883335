.how-it-works {

	.animation-trigger.in-view ~ {
		.how-it-works__left  {opacity: 1;}
	}

	&__left {
		margin-bottom: $spacing;
		opacity: 0;
		transition: $animationDuration opacity ease-in-out;
		

		&-top {
			margin-bottom: $spacing;
		}
	}

	&__cta {
		&-link-wrapper {
			padding-right: $spacing;
			width: 60%;

			a {width: 100%;}
		}

		&-image {
			margin-left: auto;
			width: 40%;
		}

		&:first-child {
			border-bottom: 1px solid rgba($white, .45);
			margin-bottom: 1rem;
			padding-bottom: 1rem;
		}
	}

	&:first-child {
		
		.how-it-works__left {
			animation: opacity $animationDuration ease-in-out both;
			opacity: 1;
		}
	}

	@include media($screen-sm) {

		&__left {
			margin-bottom: 0;
			padding-right: 3rem;
		}

		&__right {
			padding-left: 3rem;
		}
	}
}