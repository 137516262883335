.advantages {

	

	&__left {
		margin-bottom: 3rem;
		opacity: 0;
		transition: $animationDuration opacity ease-in-out;
	}

	&__right {

	}

	&__row {
		margin-bottom: 2rem;
		opacity: 0;
		transform: translate3d(3rem, 0,0);
		transition: $animationDuration opacity ease-in-out, $animationDuration transform ease-in-out;

		&-top {
			margin-bottom: .75rem;
		}

		&-icon {
			@include size(3rem);
			position: relative;

			img { @include object-fit(contain, left center); }
		}

		&.in-view {
			opacity: 1;
			transform: translate3d(0, 0,0);
		}
	}

	.animation-trigger.in-view + .advantages__left {
		opacity: 1;
	}

	@include media($screen-sm) {

		&__left {
			margin-bottom: 0;
			padding-right: $spacing;
			width: 50%
		}

		&__right {
			padding-left: $spacing;
			width: 50%;
		}

		&__row {

			&-icon { @include size(4rem); }
		}
	}
}