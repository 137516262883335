/**
 * Breadcrumb styles
 * DBS>Interactive
 */

.breadcrumbs {
    padding: 1rem 0;
    
    a {
        color: $primaryColor;
        font-weight: 400;
        text-decoration: none;
        
        &:active,
        &:focus,
        &:hover {
            color: lighten($primaryColor, 15%);
        }
    }
}
