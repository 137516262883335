.featured-builds {

	&__heading {
		margin-bottom: $spacing;
		opacity: 0;
		transition: $animationDuration opacity ease-in-out;

		&.in-view {
			opacity: 1;
		}

		.arrow-link {
			padding-right: 3rem;
			&::after {@include size(2rem)}
		}
	}

	&__wrapper {
		display: grid;
			grid-template-columns: repeat(1, 1fr);
			gap: $spacing;
	}

	&__img {
		height: 0;
		overflow: hidden;
		padding-bottom: 79%;
		position: relative;

		img { @include object-fit(cover, center); }
	}

	&__item {
		opacity: 0;
		transform: translate3d(0, 1.5rem,0);
		transition: $animationDuration opacity ease-in-out, $animationDuration transform ease-in-out;

		$buildInterval: .25s;
		&:nth-child(2) {transition-delay: $buildInterval;}
		&:nth-child(3) {transition-delay: $buildInterval*2;}

		&.in-view {
			opacity: 1;
			transform: translate3d(0, 0,0);
		}
	}

	@include media($screen-sm) {

		&__wrapper {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}