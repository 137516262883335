/**
 * Pagination Styles
 * DBS>Interactive
 */
.pagination {
	margin-top: 1em;
	margin-bottom: 1em;
	text-align: center;
    
    .button {
        &.previous {
            padding: 1.5rem 2rem 1.5rem 4rem;
            
            &::after,
            &::before {
                left: 1.25rem;
                right: unset;
                transform: rotate(180deg) translate(0%, 50%);
            }
            
            &:hover {
                &::after,
                &::before {
                    transform: rotate(180deg) translate(.5rem, 50%);
                }
            }
        }
    }

	&__link,
	&__more,
	&__current,
	.page-numbers {
		display: inline-block;
		padding: 0.5em 1em;
		text-decoration: none;
		border-radius: 3px;
		transition: .25s ease-in-out;
	}

	&__link,
	.page-numbers {
		border: 1px solid $grayDarker;
		font-weight: lighter;
		&:focus,
		&:hover {
			background: $grayDarker;
			color: $white;
		}
	}

	&__current {
		background: $grayDarker;
		border: 1px solid $grayDarker;
		font-weight: bold;
		color: $white;
	}
}

.product-pagination__links {
    >a,
    >span {
        padding: 1em;
        border: 1px solid $gray;
    }
}