.kit-slider {

	&__left {
		margin-bottom: 1.5rem;
		position: relative;
		z-index: 4;

		hr {
			border-color: $teal;
			margin: 1rem auto 1rem 0;
			width: 4rem;
		}
	}

	&__right {
		position: relative;

		&::before {
			background: $grayDarker;
			content: '';
			height: 100%;
			position: absolute;
				top: 0;
				right: 100%;
			width: 100vw;
			z-index: 2;
		}
	}

	&__cell {
		background: $gray;
		margin-right: 1rem;
		overflow: hidden;
		padding: 1rem 1rem 0 1rem;
		position: relative;
		width: 60%;

		&-heading {
			padding-bottom: 6rem;
			padding-right: 8rem;
		}

		&-image {
			height: 75%;
			position: absolute;
				bottom: 0;
				right: -1rem;
			width: 83%;

			img { @include object-fit(contain, bottom right); }
		}

		&.the-a-frame,
		&.the-duplex {
			.kit-slider__cell-image {
				bottom: -1rem;
				height: 90%;
				right: -2rem;
				width: 108%;
			}
		}

		&.the-a-frame .kit-slider__cell-image {right: -3rem;}

		&.the-modern .kit-slider__cell-image,
		&.the-tiny .kit-slider__cell-image {width: 110%; right: -2.25rem; bottom: -.15rem; }

		&.the-tiny .kit-slider__cell-image {bottom: -1rem;}

		&.the-ranch .kit-slider__cell-image {
			bottom: -.5rem;
			width: 90%;
		}
	}

	&__buttons {

		.flickity-button.previous {margin-right: 1.5rem;}
	}

	.flickity-button {
		background: url('icons/slider-arrow.svg') no-repeat center !important;
			background-size: 100%;
		transition: .25s opacity;
		transform: none;
		position: static;

		&.previous {
			background-image: url('icons/slider-arrow-back.svg') !important;
		}

		&:hover,
		&:focus {
			opacity: .7;
		}
	}

	.flickity-viewport {
		overflow: visible;
	}

	@include media($screen-sm) {

		&__left {
			margin-bottom: 0;
			padding-right: 3rem;
			width: 40%;
		}

		&__right {
			width: 60%;
		}

		&__cell {
			width: 33%;
		}
	}

	@include media($screen-md) {

		&__left {
			width: 20%;
		}

		&__right {
			width: 80%;
		}

		&__cell {
			width: 25%
		}
	}
}