.kits-grid {
	@include vertical-padding();
	background: linear-gradient(152deg, #284750 0%, #211F1F 100%);
	margin-top: 0;

	&__heading {
		animation: opacity 1.5s ease-in-out 0s both;
		margin-bottom: 3rem;

		hr {
			border-color: $teal;
			border-width: 5px;
			margin: $spacing auto;
			width: 5rem;
		}

		p {
			font-size: 1.125rem;
			margin: 0 auto;
			max-width: 34rem;
		}
	}

	&__kit {
		background: $charcoal;
		margin-bottom: $spacing;
		opacity: 0;
		padding: #{$spacing*2} 0 0 #{$spacing};
		transform: translate3d(0,1.5rem,0);
		transition: $animationDuration opacity ease-in-out, $animationDuration transform ease-in-out;

		&.in-view {
			opacity: 1;
			transform: translate3d(0,0,0);
		}

		&-sizes {

			ul {
				display: flex;
					align-items: start;
					flex-direction: column;
				list-style: none;
				margin: 0 0 $spacing;
				padding: 0;
	
				li {
					margin-bottom: .75rem;

					a {
						background: $grayDarker;
						display: inline-block;
						padding: .65rem;
						text-decoration: none;
						width: 100%;
					}
				}
			}
		}

		&-image {
			margin-left: auto;
			width: 60%;
		}
	}

	@include media($screen-sm) {

		&__grid {
			display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: auto;
				gap: $spacing;
		}

		&__kit {
			margin-bottom: 0;

			&:nth-child(even) {transition-delay: .25s;}

			&-sizes {

				ul {
					display: grid;
						grid-template-columns: repeat(2, 1fr);
						grid-template-rows: repeat(3, 1fr);
						gap: .75rem;
					
					li {
						margin-bottom: 0;

						&:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
						&:nth-child(2) { grid-area: 2 / 1 / 3 / 2; }
						&:nth-child(3) { grid-area: 3 / 1 / 4 / 2; }
						&:nth-child(4) { grid-area: 1 / 2 / 2 / 3; }
						&:nth-child(5) { grid-area: 2 / 2 / 3 / 3; }
						&:nth-child(6) { grid-area: 3 / 2 / 4 / 3; }
					}
				}
			}
		}
	}

	@include media($mobileNavBP) {
		padding-top: 12rem;
	}
}