.testimonial {

	&__text {
		background: url('/icons/quote.svg') no-repeat left top;
			background-size: 2rem auto;
		margin-bottom: 3rem;
		padding-left: $spacing*2;

		.h6 {font-size: 1rem;}
	}

	&__image {

		&-wrapper {
			height: 0;
			overflow: hidden;
			padding-bottom: 65%;
			position: relative;

			img { @include object-fit(cover, center); }
		}
	}

	@include media($screen-sm) {
		
		&__text {
			background-size: 2.5rem;
			margin-bottom: 0;
			padding-left: $spacing*3;
			padding-right: $spacing*2;
		}

		&__image {
			padding-left: $spacing*3;
		}
	}
}