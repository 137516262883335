/**
 * Sitemap page styles
 * DBS>Interactive
 */

.sitemap {

    &__section {
        margin-bottom: #{$spacing * 2};
    }
}
