.story-slider {
	position: relative;

	.contain {
		padding-right: 3rem;
	}

	&__slides {
		position: relative;
		width: 100%;
	}

	&__text {
		margin-bottom: 3rem;
		position: relative;
		z-index: 5;
		
		&--block {
			margin-right: 1.5rem;
			width: 100%;
		}
	}

	&__images {

		&--block {
			margin-right: 1.5rem;
			width: 100%;
		}
	}

	.flickity-button {
		background: #232323;
		border-radius: 0;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		height: 40%;
		width: 3.5rem;
		z-index: 10;

		&.next {	
			right: 0;
		}

		&.previous {
			left: 0;
		}

		svg {
			left: 27%;
			width: 1.75rem;

			fill: #fff;
		}

		&:disabled {display: none;}
	}

	&__images .flickity-viewport {
		overflow: visible;
	}

	&__caption {
		margin-top: .75rem;
		text-align: center;
	}

	@include media($screen-sm-max, 'max') {
		.flickity-button {
			&.next {right: -3rem;}
			&.previous {left: -1.5rem;}
		}
	}

	@include media($screen-sm) {
		&.layout {margin: 6rem auto;}
		
		.contain {
			padding-right: 1.5rem;
		}

		&::after {
			opacity: 0.64;
			background: linear-gradient(270deg, #232323 0%, rgba(35, 35, 35, 0.00) 100%);
			content: '';
			height: 100%;
			position: absolute;
				right: 0;
				top: 0;
			width: 6rem;
			z-index: 1;
		}

		&__text {
			margin-bottom: 0;
			padding-right: 4rem;
			width: 50%;

			p {
				font-size: 1.125rem;
			}
		}

		&__images {
			width: 50%;

			&::before {
				background: $bodyBackground;
				content: '';
				height: 110%;
				position: absolute;
					right: 100%;
					top: 0;
				width: 100vw;
				z-index: 1;
			}
		}

		.flickity-button {
			&.previous {
				left: -3rem;
			}

			&.next {	
				right: -1.5rem;
			}
		}
	}

	@include media($screen-md) {

		&__slide {
			margin-right: 3rem;
		}
	}

	@include media(79rem) {
		.flickity-button {
			&.next {	
				right: auto;
				left: 46.25vw;
			}
		}
	}

	@include media(100rem) {
		.flickity-button {
			&.next {	
				right: auto;
				left: 47vw;
			}
		}
	}

	@include media(117rem) {
		.flickity-button {
			&.next {	
				right: auto;
				left: 47.5vw;
			}
		}
	}

	@include media(141rem) {
		.flickity-button {
			&.next {	
				right: auto;
				left: 48vw;
			}
		}
	}

	@include media($screen-sm-max, 'max') {
		.flickity-button {
			top: auto;
			bottom: 0;
			transform: none;
			height: 40%;
			width: 2.5rem;
	
			svg {
				left: 20%;
				width: 1rem;
			}
		}

		&__images {

			&--block {
				margin-right: 0;
				padding-left: 1rem;
				// padding-right: 1rem;
			}
		}
	}
}