.kit-type-overview {
    .flickity-viewport {
        width: 100%;
    }

    .flickity-prev-next-button {
        display: none; // prev and next buttons not needed on this slider
    }

    &__navigation {
        display: flex;
        margin-bottom: -1em;
        padding: 0;
        z-index: 1;

        .flickity-prev-next-button {
            &.previous,
            &.next {
                border-radius: 0;
                height: 4em;
                display: inline-block;
                transform: none;
            }

            &.previous {
                left: 0;
                right: auto;
                top: 0;
            }
            &.next {
                right: 0;
                top: 0;
            }
            &:disabled {
                display: none;
            }
        }

        .kit-type-overview__item {
            width: 40.33%;
            border-right: 2px solid $white;
            display: inline-block;
            position: relative;
            overflow: hidden;
            margin-bottom: 1em;
            overflow: visible;

            @include media($screen-md) {
                width: 20%;
            }

            &.is-selected .kit-type-overview__item--button {
				background-color: $tealDarker;
                font-weight: 600;
                transition: background-color .35s linear;
                position: relative;
            }
        }

        .kit-type-overview__item--button { // button elements. Controls for the slider
            background-color: $tealDark;
            border: 0;
            border-bottom: 2px solid $white;
			color: $white;
            height: 4em;
            overflow: hidden;
            position: relative;
            width: 100%;

            &:focus,
            &:hover {
                background-color: $tealDarker;
                transition: background-color .35s linear;
            }
        }
    }

    &__preview {
        position: relative;
        z-index: 0;
        height: 100%;

        .flickity-viewport {
            // Workaround for viewport collapsing when cells with images are set to 100%;
            // https://github.com/metafizzy/flickity/issues/559
            padding-top: 120%;
            .flickity-slider {
                margin-top: -120%;
            }
            @include media($screen-sm) {
                padding-top: 50%;
                .flickity-slider {
                    margin-top: -50%;
                }
            }
        }
        
        .pause-start-slider {
            opacity: 0;
        }
        
        .carousel {
            height: 600px;
            
            @include media($screen-sm-max, 'max') {
                height: 1100px;
            }
        }

        .carousel-cell {
            justify-content: space-between;
            color: black;
            display: flex;
            height: 100%;
            left: 100%;
            position: absolute;
            transition: left .5s ease-in-out, opacity .5s ease-in-out;
            width: 100%;
            
            &.is-selected {
                left: 0px;
                opacity: 1;
                transform: translateX(0%);
            }
            
            &:not(.is-selected) {
                opacity: 0;
            }
            
            .flickity-button {
                display: block;
            }
            
            &__floorplan-slider,
            &__main-content {
                width: calc(50% - #{$spacing / 2});
            }

            @include media($screen-sm) {
                padding: 4em 4em 2em;
            }

        }

        &--image {
            display: block;
            background-color: $charcoal;
			height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            overflow: hidden;

            img { @include object-fit(cover, center); }
        }

        &--content {
			background: rgba(28, 33, 38, 0.95);
            display: inline-block;
			margin: 1rem;
            width: calc(100% - 2rem);
            padding: 1em;
            position: relative;

            @include media($screen-sm) {
				margin: 0;
                max-width: 19em;
                padding: 4em 2em;
            }
        }
    }

    @include media($screen-sm-max, 'max') {
        .carousel-cell {
            flex-direction: column;
            padding: $spacing;
            
            &__floorplan-slider,
            &__main-content {
                width: 100%;
            }
            
            &__main-content {
                .button {
                    white-space: normal;
                }
            }
        }
        
        .pause-start-slider {margin: .5rem;}
                
        &__navigation {
            background-color: $tealDarkest;
            flex-wrap: wrap;
            margin-bottom: 0;

            .kit-type-overview__item {
                margin-bottom: 0;
                width: 50%;
            }
        }
    }
}
