.blueprint {
	padding: 6rem 0 !important;
	position: relative;

	&__text {
		opacity: 0;
		transition: opacity $animationDuration ease-in-out;
	}

	.animation-trigger.in-view ~ {
		.contain .blueprint__text {opacity: 1;}
	}

	&__graphic {
		height: 80%;
		position: absolute;
			top: 50%;
			left: 48%;
		transform: translateY(-50%);
		width: 50%;

		svg {
			height: 100%;
			width: 100%;
		}
	}

	#house-drawing {
		.line {
			fill: transparent;
			stroke: rgba($white, .25);
			stroke-width: 3;
			stroke-dashoffset: 10000;
			stroke-dasharray: 10000;
			transition: 8s;
		}

		&.in-view .line {stroke-dashoffset: 0;}
	}

	@include media($screen-sm) {
		padding: 7.5rem 0 !important;

		&__text {
			max-width: 35rem;
		}

		&.text-center {

			.blueprint__text {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&.text-right {

			.blueprint__text {margin-left: auto;}

			.blueprint__graphic {
				left: auto;
				right: 48%;
			}
		}
	}
}