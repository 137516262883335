.card-slider {

    &__left-content {
        max-width: 24em;
        margin-bottom: 2em;
        padding-bottom: 3rem;
        position: relative;
        z-index: 3;
    }
    
    &-slider {
        display: flex;
        
        &__cell {
            background: #f7f8f9;
            // filter: drop-shadow(0px 0px 30.276px rgba(0, 0, 0, 0.09));
            margin-right: 1.5rem;
            position: relative;
            overflow: hidden;
            width: 90%;

            &-image {
                overflow: hidden;
                padding-bottom: 65%;
                position: relative;

                img { @include object-fit(cover, center); }
            }

            &-text {
                padding: 1.5rem 1rem;

                h1, h2, h3, h4, h5, h6, p {
                    margin-bottom: .75rem;
                }
            }
        }
    }

    .flickity-viewport {
        overflow: visible;
        position: relative;
        width: 100%;

        &::after {
            background: $white;
            content: '';
            height: 100%;
            position: absolute;
                top: 0;
                right: 100%;
            width: 100vw;
        }
    }

    .flickity-button {
        background-color: $teal;
        top: auto;
        bottom: 0;

        &.previous {left: -11rem;}
        &.next {
            left: -6rem;
            right: 0;
        }

        .flickity-button-icon {fill: $white;}

        &.flickity-prev-next-button {
            transform: translateY(0);
        }

        @include media($screen-md-max, 'max') {
            bottom: 0;
            top: -4rem;

            &.previous {
                left: auto;
                right: 5rem;
            }

            &.next {
                left: auto;
                right: 0;
            }
        }
    }

    @include media($screen-sm) {

        &-slider {
            flex: 1;
            
            &:focus {
                box-shadow: none !important;
            }
        }
        
        &-slider__cell {
            width: 40%;
        }
        
        .flickity-viewport:focus,
        .flickity-slider:focus {
            box-shadow: none !important;
        }
    }

    @include media($screen-md) {

        &__left-content {
            padding-right: 3rem;
        }
    }
    
    +.layout.card-slider .contain {
        border-top: 2px solid $teal;
        padding-top: #{$spacing * 2};
        margin-bottom: #{$spacing * 2};
    }
}
