/**
 * Exploding house styles
 *
 * Includes static styles and animation
 */

 .exploding-house-container {
    color: #909193;
    display: block;
    margin: 0 auto;
    max-height: 700px;
    max-width: 700px;
    overflow: visible;
    position: relative;
    width: 55vw;
	height: 75vw;
	width: 75vw;
    margin: 0 auto;

   @include media ($screen-sm) {
	   height: 100vw;
	   width: 100vw;
   }

	img {
		height: auto;
		position: absolute;
	}
}

div.exploding-house {
	position: relative;
	z-index: 1;
}

.exploding-house.panel-00 { // gray floor
	top: 37.25%;
	left: 29%;
	width: 55.7%;
}
.exploding-house.panel-01 { // back wall
	top: 21.5%;
	left: 54.75%;
	width: 30%;
}
.exploding-house.panel-02 { // left wall piece (whole)
	top: 21.15%;
	left: 29%;
	width: 26.5%;
}
.exploding-house.panel-03 { // closet with door
	top: 25.5%;
	left: 44.25%;
	width: 21.5%;
}
.exploding-house.panel-04 { // second floor panel
	top: 35%;
	left: 30.25%;
	width: 35%;
}
.exploding-house.panel-05 { // front panel with doors and windows
	top: 28.95%;
	left: 28.5%;
	width: 30%;
}
.exploding-house.panel-06 { // porch cover
	top: 37.75%;
	left: 20.75%;
	width: 37%;
}
.exploding-house.panel-07 { // front-most wall piece 1 (back to front)
	top: 35.5%;
	left: 77.15%;
	width: 7.75%;
}
.exploding-house.panel-08 { // front-most wall piece 2 (back to front)
	top: 37.5%;
	left: 71%;
	width: 6.85%;
}
.exploding-house.panel-09 { // front-most wall piece 3 (back to front)
	top: 39.15%;
	left: 65%;
	width: 7.1%;
}
.exploding-house.panel-10 { // front-most wall piece 4 (back to front)
	top: 41.15%;
	left: 58.25%;
	width: 7.25%;
}

// back
.exploding-house.panel-11 { // roof piece (square) - right back
	top: 18.5%;
	left: 39.8%;
	width: 20.5%;
}
.exploding-house.panel-12 { // roof piece (square) - left back
	top: 22.2%;
	left: 26.1%;
	width: 20.5%;
}

// middle
.exploding-house.panel-13 { // roof piece (square) - right middle
	top: 22.35%;
	left: 48.5%;
	width: 20.5%;
}
.exploding-house.panel-14 { // roof piece (square) - left middle
	top: 26.35%;
	left: 34.4%;
	width: 20.5%;
}

// front
.exploding-house.panel-15 { // roof piece (largest partial) - right
	top: 26.25%;
	left: 56.75%;
	width: 20.45%;
}
.exploding-house.panel-16 { // roof piece (smallest partial) - left front-most
	top: 30.25%;
	left: 42.5%;
	width: 18.25%;
}
.exploding-house.panel-17 { // roof piece (second largest partial) - right front-most
	top: 30.15%;
	left: 67%;
	width: 18.5%;
}

// Animation Variables
$explodeDuration: 4s;
$frontStagger: .35s;
$roofStagger: .2s;

// Animated State
.exploding-house-container.animate {
	// House Body
	.panel-00 { animation: panel-00 $explodeDuration linear 1; }
	.panel-01 { animation: panel-01 $explodeDuration linear 1; }
	.panel-02 { animation: panel-02 $explodeDuration linear 1; }
	.panel-03 { animation: panel-03 $explodeDuration linear 1; }
	.panel-04 { animation: panel-04 $explodeDuration linear 1; }
	.panel-05 { animation: panel-05 $explodeDuration linear 1; }
	.panel-06 { animation: panel-06 $explodeDuration linear 1; }

	// Front Walls (These run in reverse order)
	.panel-10 { animation: panel-10 $explodeDuration linear 1; }
	.panel-09 { animation: panel-09 $explodeDuration - $frontStagger * 2 linear 1 $frontStagger; }
	.panel-08 { animation: panel-08 $explodeDuration - $frontStagger * 4 linear 1 $frontStagger * 2; }
	.panel-07 { animation: panel-07 $explodeDuration - $frontStagger * 6 linear 1 $frontStagger * 3; }

	// // Roof
	.panel-11 { animation: panel-11 $explodeDuration linear 1; }
	.panel-12 { animation: panel-12 $explodeDuration - $roofStagger * 2 linear 1 $roofStagger; }
	.panel-13 { animation: panel-13 $explodeDuration - $roofStagger * 4 linear 1 $roofStagger * 2; }
	.panel-14 { animation: panel-14 $explodeDuration - $roofStagger * 6 linear 1 $roofStagger * 3; }
	.panel-15 { animation: panel-15 $explodeDuration - $roofStagger * 8 linear 1 $roofStagger * 4; }
	.panel-16 { animation: panel-16 $explodeDuration - $roofStagger * 10 linear 1 $roofStagger * 5; }
	.panel-17 { animation: panel-17 $explodeDuration - $roofStagger * 12 linear 1 $roofStagger * 6; }
}

// Paused State
.exploding-house-container.paused .exploding-house {animation-play-state: paused;}


/*
 * Animations
 */

// House Body
@keyframes panel-00 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(0,10%,0);}
	80% {transform: translate3d(0,10%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-01 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(15%,-25%,0);}
	80% {transform: translate3d(15%,-25%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-02 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(-35%,-15%,0);}
	80% {transform: translate3d(-35%,-15%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-03 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(-5%,-15%,0);}
	80% {transform: translate3d(-5%,-15%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-04 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(0%,-35%,0);}
	80% {transform: translate3d(0%,-35%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-05 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(-40%,40%,0);}
	80% {transform: translate3d(-40%,40%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-06 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(-50%,70%,0);}
	80% {transform: translate3d(-50%,70%,0);}
	100% {transform: translate3d(0,0,0);}
}


// Front Walls
@keyframes panel-07 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(75%,10%,0);}
	80% {transform: translate3d(75%,10%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-08 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(55%,30%,0);}
	80% {transform: translate3d(55%,30%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-09 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(35%,50%,0);}
	80% {transform: translate3d(35%,50%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-10 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(15%,70%,0);}
	80% {transform: translate3d(15%,70%,0);}
	100% {transform: translate3d(0,0,0);}
}


// Roof
@keyframes panel-11 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(5%, -135%,0);}
	80% {transform: translate3d(5%, -135%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-12 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(-30%,-115%,0);}
	80% {transform: translate3d(-30%,-115%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-13 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(15%,-115%,0);}
	80% {transform: translate3d(15%,-115%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-14 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(-15%,-105%,0);}
	80% {transform: translate3d(-15%,-105%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-15 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(25%,-110%,0);}
	80% {transform: translate3d(25%,-110%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-16 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(0,-130%,0);}
	80% {transform: translate3d(0,-130%,0);}
	100% {transform: translate3d(0,0,0);}
}

@keyframes panel-17 {
	0% {transform: translate3d(0,0,0);}
	20% {transform: translate3d(40%,-95%,0);}
	80% {transform: translate3d(40%,-95%,0);}
	100% {transform: translate3d(0,0,0);}
}