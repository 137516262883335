// Not a flex layout. An individual component of the kit single page

.kit-includes {

	&__columns {
		overflow: 0;
		position: absolute;
		visibility: hidden;

		&.active {
			opacity: 1;
			position: relative;
			visibility: visible;
		}
	}

	&__column {
		border-bottom: 1px solid rgba(White, .2);
		margin-bottom: $spacing;
		padding-bottom: $spacing;

		h4:not(.kit-includes__cta-heading) {
			font-size: 1rem;
			font-family: $font-poppins;
			font-weight: 400;
		}

		ul {
			list-style: initial !important;
		}
	}

	&__cta {
		padding: $spacing;

		&-image {
			margin: 0 0 -4.75rem;
		}
	}

	@include media($screen-sm) {

		&__columns {
			display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, 1fr);
				gap: 6rem;
		}

		&__column {
			border: none;
			margin-bottom: 0;
			padding-bottom: 0;

			@include media($screen-md-max, 'max') {
				&:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
				&:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
				&:nth-child(3) { grid-area: 2 / 1 / 3 / 3; }
			}

			&:nth-child(1) {
				position: relative;

				&::after {
					border-right: 1px solid rgba($white, .2);
					content: '';
					height: 100%;
					position: absolute;
						right: -3rem;
						top: 0;
				}
			}
		}

		&__cta {
			align-self: start;

			&-image {
				margin: 0 0 -8.75rem;
			}
		}
	}

	@include media($screen-md) {
		&__columns {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 1fr;
		}

		&__cta {

			&-image {
				margin: 0 -4.75rem -4.75rem -4.75rem;
			}
		}
	}
}