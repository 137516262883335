.cards {

	&__heading {
		margin: 0 auto 3rem;
		opacity: 0;
		max-width: 42rem;
		transition: $animationDuration opacity ease-in-out;

		&.in-view {
			opacity: 1;
		}
	}

	&__item {
		background: $charcoal;
		margin-bottom: 2rem;
		opacity: 0;
		padding: $spacing;
		transform: translate3d(0, 1.5rem,0);
		transition: $animationDuration opacity ease-in-out, $animationDuration transform ease-in-out;

		$cardInterval: .25s;
		&:nth-child(2) {transition-delay: $cardInterval;}
		&:nth-child(3) {transition-delay: $cardInterval*2;}
		&:nth-child(4) {transition-delay: $cardInterval*3;}
		&:nth-child(5) {transition-delay: $cardInterval*4;}
		&:nth-child(6) {transition-delay: $cardInterval*5;}
		&:nth-child(7) {transition-delay: $cardInterval*6;}
		&:nth-child(8) {transition-delay: $cardInterval*7;}
		&:nth-child(9) {transition-delay: $cardInterval*8;}
		&:nth-child(10) {transition-delay: $cardInterval*9;}

		&.in-view {
			opacity: 1;
			transform: translate3d(0, 0,0);
		}

		p, h1, h2, h3, h4, h5, h6 {
			
			&:not(:last-child) {margin-bottom: .75rem;}
		}
	}

	&__wrapper {

	}

	&.no-card-background {

		.cards__item {
			background: transparent;
			padding: 0;
		}
	}

	&.checkbox-cards {

		.cards__item {
			padding-left: 2rem;
			position: relative;

			&::before {
				@include size(1.25rem);
				background: url('/icons/teal-check.svg') no-repeat center;
					background-size: contain;
				content: '';
				position: absolute;
					top: 0;
					left: 0;
			}
		}

		.cards__wrapper { gap: #{$spacing*2} #{$spacing} !important; }
	}

	@include media ($screen-sm) {

		&__item {
			margin-bottom: 0;
		}

		&__wrapper {
			display: grid;
				grid-template-rows: auto;
				gap: $spacing;

			&.two-across,
			&.four-across {grid-template-columns: repeat(2, 1fr);}
	
			&.three-across {grid-template-columns: repeat(3, 1fr);}
		}

		&.no-card-background {

			.cards__wrapper { gap: $spacing*2; }
		}
	}

	@include media($screen-md) {

		&__wrapper {
			&.four-across {grid-template-columns: repeat(4, 1fr);}
		}
	}
}