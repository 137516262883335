.pinterest {
    padding: 0 1em;
    text-align: center;
    // height: 50em !important;
    overflow: hidden;
	
    @include media($screen-md) {
        padding: 0;
    }

    .partial-pinterest__items { // ul
        list-style: none;
        padding: 0;
        position: relative;
    }

    .partial-pinterest__item { // li
        list-style: none;
        // border-right: .25em solid $white;
        width: 100%;
        @include media($screen-sm-max,'max') {
            left: 0 !important;
        }

        @include media($screen-sm) {
            float: left;
            width: calc(33% - .6rem);
        }

        @include media($screen-md) {
            width: calc(25% - .6rem);
        }

        a {
            display: block;
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;

            &:after {
                background: rgba($charcoal, .3);
                content: '';
                height: 100%;
                opacity: 0;
                position: absolute;
                    top: 50%;
                    left: 50%;
                transform: translate(-50%,-50%);
                transition: opacity .5s;
                width: 100%;
            }

            &:hover,
            &:focus {
                &:after {
                    opacity: 1;
                }
            }

            img {
                display: block;
                height: 100%;
                width: 100%;
            }
        }
    }
}
