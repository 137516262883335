// Not a flex layout. An individual component of the kit single page

.kit-header {

	&__left {

		&-main {
			margin-bottom: 2rem;

			.kit-header__h1 {
				animation: opacity 1.5s ease-in-out 0s both;
			}

			p {animation: opacity 1.5s ease-in-out .25s both;}
		}

		.get-quote-wrapper,
		.kit-header__left-sizes {animation: opacity 1.5s ease-in-out .5s both;}

		&-sizes {
			margin-bottom: 2rem;

			h3 {margin-bottom: .75rem;}

			ul {
				padding: 0;
				list-style: none;
				margin: 0;

				li {
					border-top: 1px solid rgba($white, 0.20);
				}
			}
		}

		.kit-header__button {
			padding: 1rem;
			width: 100%;

			&.active {
				font-weight: bold;
				position: relative;

				&::before {
					background: url('/icons/selected-size.svg') no-repeat left center;
						background-size: auto 100%;
					content: '';
					height: 80%;
					position: absolute;
						top: 50%;
						left: -.5rem;
					transform: translateY(-50%);
					width: 1rem;
				}

				&:focus,
				&:active {box-shadow: none !important;}
			}
		}
	}

	&__gallery {
		padding-bottom: 3rem;
        padding-top: 1.5em;
		position: relative;

		&-wrapper {
			opacity: 0;
			position: absolute;
			visibility: hidden;

			&.active {
				opacity: 1;
				position: static;
				visibility: visible;
			}
		}

		&-main {
			animation: opacity 1.5s ease-in-out 0s both;
			margin-bottom: 1rem;
		}

		.kit-header__gallery-open {
			position: absolute;
				bottom: 0;
				left: 50%;
			transform: translateX(-50%);
		}

        &-nav {
            display: flex;
                flex-direction: row;
            max-height: 33rem;
            overflow: auto;
			position: relative;

            @include media($screen-sm-max, 'max') {
                display: flex;
                    flex-wrap: nowrap;
				margin-right: -$spacing;
                overflow-x: auto;

                .kit-header__gallery-button {
					animation: opacity 1.5s ease-in-out 0s both;
                    flex: 0 0 auto;
                    margin-right: .25rem;
                    padding-bottom: 25%;
                    width: 7rem;
                }
            }
        }

        &-button {
			animation: opacity 1.5s ease-in-out 0s both;
            appearance: none;
            border: none;
            overflow: hidden;
            margin-bottom: .5rem;
            padding-bottom: 65%;
            position: relative;
            
            img { @include object-fit(cover,center); }

			$buttonInterval: .25s;
			&:nth-child(2) {transition-delay: $buttonInterval;}
			&:nth-child(3) {transition-delay: $buttonInterval*2;}
			&:nth-child(4) {transition-delay: $buttonInterval*3;}
			&:nth-child(5) {transition-delay: $buttonInterval*4;}
			&:nth-child(6) {transition-delay: $buttonInterval*5;}
			&:nth-child(7) {transition-delay: $buttonInterval*6;}
			&:nth-child(8) {transition-delay: $buttonInterval*7;}
			&:nth-child(9) {transition-delay: $buttonInterval*8;}
			&:nth-child(10) {transition-delay: $buttonInterval*9;}

            &.active {
                box-shadow: none !important;
                cursor: not-allowed;
                opacity: .5;
            }
        }

		&-open {
			background: url('/icons/view-all-dots.svg') no-repeat left;
				background-size: 1.25rem 100%;
			margin-top: .75rem;
			padding-left: 2rem;
		}
    }

	@include media($screen-sm) {
		&__left {
			padding-right: 3rem;
			width: 40%;
		}

		&__gallery {
            flex-basis: 65%;
            padding-left: 3rem;
			padding-top: 0;

			&-wrapper {
				display: flex;
			}

            &-main {
                flex-basis: 80%;
				margin-bottom: 0;
                padding-right: 1em;
            }
        
            &-nav {
                flex-basis: 20%;
                flex-direction: column;
            }

			.kit-header__gallery-open {
				left: auto;
				right: 1.5rem;
				transform: none;
			}
        }

		&__right {
			width: 60%;
		}
	}

	@include media($mobileNavBP) {
		margin-top: 0;
		padding-top: 12rem;
	}
}

.kit-header-lightbox,
.floorplan-lightbox {
	@include size(100%);
	background:rgba($black, .75);
	opacity: 0;
	position: fixed;
		top: 150%;
		left: 0;
	visibility: hidden;
	z-index: 5000;

	&.open {
		opacity: 1;
		top: 0;
		visibility: visible;
	}

	&__container {
		background: $white;
		height: calc(100% - 2rem);
		max-height: 100vw;
		max-width: 65rem;
		padding: 1rem;
		position: absolute;
			top: 50%;
			left: 50%;
		transform: translate(-50%,-50%);
		width: calc(100% - 2rem);
		z-index: 5001;
	}

	.flickity-viewport {
		width: 100%;
	}

	&__main {
		height: calc(80% - 2rem);
		margin-bottom: 2rem;
		position: relative;
		width: 100%;

		&-image {
			height: 100%;
			width: 100%;

			img {@include object-fit(contain,center);}
		}

	}

	&__nav {
		display: flex;
		height: 20%;
		list-style: none;
		margin: 0 -1rem 0 0;
		overflow: auto;
		padding: 0;
		width: 100%;

		button {
			appearance: none;
			background: none;
			border: none;
			cursor: pointer;
			display: block;
			margin-bottom: .75rem;
			padding-bottom: 75%;
			position: relative;
			width: 100%;
		}

		img {@include object-fit(contain, center);}
	}

	&__close {
		@include size(3rem);
		background: $white;
		padding: .65rem;
		position: absolute;
			top: 0;
			right: 0;
		
	}

	@include media($screen-sm) {		

		&__container {
			height: calc(100% - 4rem);
			display: flex;
			max-height: 38rem;
			max-width: 62rem;
		}

		&__main {
			height: 100%;
			margin-bottom: 0;
			margin-right: 1rem;
			width: calc(85% - 1rem);

			&-image {

				img {@include object-fit(contain, center);}
			}
		}

		&__nav {
			flex-direction: column;
			height: 100%;
			margin: 0;

			overflow: auto;
			width: 15%;

			li {
				margin-bottom: 1rem;
				margin-right: 0;
				padding-bottom: 75%;
				width: 100%;

				button {
					display: block;
					padding-bottom: 75%;
				}
			}
		}
	}
}